@font-face {
    font-family: intro;
    src: url(../Fonts/Pilat\ Regular.otf);
}
.section1{
    background-image: url(../Backgrounds/BG_01_1440x1024px.png) ;
    background-size: 100vw 110vh;
/* background-repeat: no-repeat; */

background-attachment: fixed;
width: 100%;
height: 100vh;
    
}
.main-container {
    height:100%;
    display:flex;
  }
  
  .left-container {
    flex:1 1 0;
  }
  
  .right-container {
    flex:1 1 0;
    display:flex;
    flex-direction:column;
  }
  
  .half-containers {
    flex:1;
    overflow:auto;
  }
  .intro{
    font-family: intro !important;
    font-size:3.5vh;
    text-align: left !important;
  

  }
  .intro2{
    font-family: intro !important;
    font-size:3.5vh;
  

  }
  .disc{
      width: 25%;
      margin-left: 1rem;
  }
  .btn{
      background-color: black;
      border-radius: 8px !important;
      border: 2px solid white;
      font-size: 3.5vh;
      padding: 1rem;
      width: 14vw;
      color: white;
  }
  .wallet-adapter-button{
    background-color: black !important;
    border-radius: 8px !important;
    border: 2px solid white !important;
    font-size: 2.3vh !important;
    padding: 2vh !important;
    
  }
  .topcorner{
    position:fixed;
    top:3vh;
    right: 1vw;

  
  }
  .navPosition1{
    position: fixed;
    top: 1rem;
    right: 0;
    transform: translate(-60%,40%);
  }
  .navlink{
    font-family: intro;
    font-weight: lighter !important;
    padding: 2rem;
    font-size: 3vh;
    margin-right: 0.6vw;
  }
  .section{
    position: relative;
    top: 20vh;
    padding: 2rem;
  }
  .logo{
    position: fixed;
    top: 1rem;
    left: 0;
    transform: translate(20%,20%);
  
    
  }

  @media screen and (max-width:800px) {
    .section1{
        background-image: url(../Backgrounds/BG_01_1440x1024px.png) ;
        background-size: cover;
    /* background-repeat: no-repeat; */
    
    background-attachment: fixed;
    width: 100%;
    height: 120vh;
        
    }

    .btn{
        background-color: black;
        border-radius: 8px !important;
        border: 2px solid white;
        font-size: 2vh;
        padding: 1rem;
        width: 40vw;
        color: white;
    }   
    .social{
        text-align: center !important;
    }
    .disc{
        width: 10%;
        margin-top: 20px;
    }
    .intro{
        font-family: intro !important;
        font-size:2.5vh;
      
    
      }
      .intro2{
        font-family: intro !important;
        font-size:2.5vh;
      
    
      }
      .navPosition1{
        display: none;
      }
      .logo{
          width: 40%;
      }
  }

  @media screen and (width:320px) {
    .logo{
        width: 30%;
    }
  }
  @media screen and (width:1024px) {
    .navlink{
      font-size: 2vh;
      position: relative;
      top: 1.5vh;
    }
    .section1{
        background-image: url(../Backgrounds/BG_01_1440x1024px.png) ;
        background-size: cover;
    /* background-repeat: no-repeat; */
    
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
        
    }
    .btn{
     
       width: 30vw;
        
    }  

   
  }