.App {
  text-align: center;


}



/* .wallet__btn{
  position: absolute;
  top: 65%; left: 1%;
  transform: translate(50%,-50%);
  z-index: 100000;
} */
.loader{
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  z-index: 100000;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

