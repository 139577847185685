.section2{
    background-image: url(../../Backgrounds/BG_02_1440x1024px.png) ;
    background-size: 100vw 110vh;
/* background-repeat: no-repeat; */

background-attachment: fixed;
width: 100%;
height: 130vh;
}
.dot {
    height: 50px;
    width: 50px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }


  @media screen and (max-width:800px) {
    .section2{

        background-size: cover;

    }

  }
  
  @media screen and (width:1024px) {
    .section2{

        background-size: cover;

    }
  }