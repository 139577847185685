@font-face {
    font-family: intro;
    src: url(../../Fonts/Pilat\ Regular.otf);
}
.section3{
 background-color: #E5E5E5;
 width: 100%;


}
.faq{
    position: relative;
   padding: 2rem;
   font-family: intro;
  text-align: left !important;
  
}