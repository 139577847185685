@font-face {
    font-family: roboto-regular;
    src: url(./Fonts/Pilat\ Regular.otf);
  }
  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5vh;
  }
  
  .countdown-item {
    color: #000;
    font-size: 40px;
    display: flex;
    font-family: roboto-regular !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height:0rem;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    margin-left: 3vw;
  }
  
  .countdown-item span {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }
  
  footer {
    background-color: #222;
    color: #000;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
  }
  
  footer p {
    margin: 10px 0;
  }
  
  footer i {
    color: red;
  }
  
  footer a {
    color: #3c97bf;
    text-decoration: none;
  }
  h1{
    font-size: 8vh;
  }
  h6{
      font-size: 2vh;
      position: relative;
      bottom: 3vh;
  }

  @media screen and (max-width:800px) {
    .countdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 2vh;
      }
   

  }
  @media screen and (width:1024px) {
    h6{
        font-size: 2vh;
    }
    h1{
        font-size: 7vh;
    }
    .countdown-wrapper {
     position: relative;
     top: 4vh;
     margin-bottom: 10vh;
      }
      .social{
          position: relative;
          top: 5vh;
      }

  }
  @media screen and (width:280px) {
    .countdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 2vh;
        flex-direction: row !important;
      }
      h6{
          font-size: 1vh;
      }
      h1{
          font-size: 3vh;
      }

   

  }